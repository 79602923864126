import { computed, ref } from 'vue'
import type { AxiosResponse } from 'axios'
import { useRoute } from 'vue-router'
import type { AddItinerarySuccessResponse } from '../types/api/response/itinerary'
import type { Modes } from '../types'
import {
  useAddItineraryMutation,
  useUpdateItineraryMutation,
} from '@/modules/Search/requests/mutations'
import type { AddItineraryPayload } from '@/modules/Search/types/api/payload/itinerary'
import useItinerarySummary from '@/modules/Search/store/itinerarySummary'
import useSearchPanelStore from '@/modules/Search/store/searchPanel'
import useAuthStore from '@/modules/Auth/store'
import { useItineraryQuery } from '@/modules/Search/requests/queries'
import useSearchPanelHistoryStore from '@/modules/Search/store/searchPanelHistory'

function useItinerary() {
  const itinerarySummaryStore = useItinerarySummary()
  const authStore = useAuthStore()
  const searchPanelStore = useSearchPanelStore()
  const searchPanelHistoryStore = useSearchPanelHistoryStore()
  const { mutateAsync: mutateAddItinerary, data: addItineraryData } =
    useAddItineraryMutation({
      meta: {
        noSuccessToast: true,
      },
    })
  const { mutateAsync: mutateUpdateItinerary } = useUpdateItineraryMutation({
    meta: {
      noSuccessToast: true,
    },
  })

  const getCurrentState: () => AddItineraryPayload = () => {
    if (
      !searchPanelStore.searchId &&
      searchPanelHistoryStore.history.searchId
    ) {
      searchPanelStore.searchId = searchPanelHistoryStore.history.searchId
    }

    return {
      searchId: searchPanelStore.searchId || '',
      ...(authStore.loggedIn
        ? { userId: searchPanelStore.getId }
        : { guestId: searchPanelStore.getId }),
      searchOptionAir: [],
      searchOptionHotel: [],
      searchOptionCar: [],
    }
  }

  const payload = ref<AddItineraryPayload>(getCurrentState())

  async function submitItinerary({ ignoreLoading = false } = {}) {
    payload.value = getCurrentState()

    if (!ignoreLoading) {
      itinerarySummaryStore.isLoading = true
    }

    let response: AxiosResponse<AddItinerarySuccessResponse, any>

    if (itinerarySummaryStore.id) {
      response = await updateItinerary()
    } else {
      response = await addItinerary()
    }

    if (!ignoreLoading) {
      itinerarySummaryStore.isLoading = false
    }

    return response
  }

  async function addItinerary() {
    const { cars, flights, hotels } = itinerarySummaryStore.itemsSearchOptions

    payload.value.searchOptionAir = flights
    payload.value.searchOptionHotel = hotels
    payload.value.searchOptionCar = cars

    const response = await mutateAddItinerary(payload.value, {
      onError(error, { searchOptionAir, searchOptionCar, searchOptionHotel }) {
        onError(error, searchOptionAir, searchOptionHotel, searchOptionCar)

        return error
      },
    })

    if (addItineraryData.value?.data) {
      itinerarySummaryStore.id = addItineraryData.value.data.data.id
    }

    return response
  }

  async function updateItinerary() {
    const { cars, flights, hotels } = itinerarySummaryStore.itemsSearchOptions

    payload.value.searchOptionAir = flights
    payload.value.searchOptionHotel = hotels
    payload.value.searchOptionCar = cars
    delete payload.value.guestId

    return await mutateUpdateItinerary(payload.value, {
      onError(error, { searchOptionAir, searchOptionCar, searchOptionHotel }) {
        onError(error, searchOptionAir, searchOptionHotel, searchOptionCar)

        return error
      },
    })
  }

  function onError(
    _error: AxiosCustomError,
    searchOptionAir?: AddItineraryPayload['searchOptionAir'],
    searchOptionHotel?: AddItineraryPayload['searchOptionHotel'],
    searchOptionCar?: AddItineraryPayload['searchOptionCar']
  ) {
    itinerarySummaryStore.isLoading = false

    const searchOptions: {
      [key in Modes]:
        | number[]
        | AddItineraryPayload['searchOptionHotel']
        | undefined
    } = {
      flight: searchOptionAir,
      hotel: searchOptionHotel,
      car: searchOptionCar,
    }

    Object.keys(searchOptions).forEach((key) => {
      const searchOption = searchOptions[key as Modes]

      if (!searchOption) {
        return
      }

      searchOption.forEach((id) => {
        const searchOption = typeof id === 'number' ? id : id.searchOption
        const item = itinerarySummaryStore.getItemBySearchOption(
          searchOption,
          key as Modes
        )

        if (!item) {
          return
        }

        itinerarySummaryStore.remove(item.uniqueId)
      })
    })
  }

  const searchId = computed(() => searchPanelStore.searchId ?? '')
  const itineraryId = computed(() => itinerarySummaryStore.id ?? '')

  const enabled = computed(
    () => !!itineraryId.value.length && !!searchId.value.length
  )

  const route = useRoute()

  function fetchItinerary() {
    return useItineraryQuery(searchId, itineraryId, {
      enabled,
      retry(_, error) {
        if (
          (error as AxiosCustomError).response?.data.errors[0].propertyName ===
          'Search Expired'
        ) {
          itinerarySummaryStore.id = null

          if (route.path === '/book') {
            itinerarySummaryStore.setItineraryHasError(true)
          }

          return false
        }
        return true
      },
    })
  }

  return {
    submitItinerary,
    fetchItinerary,
  }
}

export default useItinerary
